<template>
  <Swiper
    v-if="bannerList.length"
    :navigation="false"
    :effect="'fade'"
    :initialSlide="currentSelect"
    :thumbs="{ swiper: thumbsSwiper }"
    :modules="[Autoplay, EffectFade, Navigation, Thumbs]"
    :autoplay="{
      delay: 6000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    }"
    class="banner_main-top"
    @slideChange="slideChange"
    @beforeTransitionStart="beforeTransitionStart"
  >
    <SwiperSlide v-for="content in bannerList">
      <BannerMain :banner="content" />
    </SwiperSlide>
  </Swiper>

  <div class="flex align-items-center justify-content-sb">
    <BannerButtonCinema
      :banner="bannerList"
      :onData="handleData"
      :current-select="currentSelect"
      @set-content-item="setContentItem"
      @toggle-detail="toggleDetail"
    />
    <BannerSwiperCinema
      :sizes="sizes"
      :banner-list="bannerList"
      :current-banner="currentBanner?.vodInfo"
      :selected="currentSelect"
      @init-swiper-banner="initSwiperBanner"
      class="mini-banner-top"
    />
  </div>

  <Transition name="poster-fade">
    <PosterDetail
      v-if="showDetail"
      :content="contentItem"
    />
  </Transition>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, EffectFade, Navigation, Thumbs } from 'swiper';

import BannerMain from '@/components/ui/Banners/BannerMain';
import BannerButtonCinema from '@/components/ui/Banners/BannerButtonCinema';
import BannerSwiperCinema from '@/components/ui/Swipers/BannerSwiperCinema';
import PosterDetail from '@/components/ui/Posters/PosterDetail';

const bannerStore = useBannerStore();
const { apppage, banners2 } = storeToRefs(useBannerStore());
apppage.value = 'main';
bannerStore.clean();

await bannerStore.fetchBanner();
const bannerList = shallowRef((await banners2.value) || []);

const currentBanner = shallowRef({});
const currentSelect = shallowRef(0);

const contentItem = shallowRef({});
const setContentItem = data => (contentItem.value = data);

const showDetail = shallowRef(false);
const toggleDetail = data => (showDetail.value = data);

const sizes = ref({});
const handleData = data => {
  sizes.value = data;
};

const slideChange = swiper => {
  currentSelect.value = swiper.activeIndex;
};

// Костыль для фейда слайдов. При обртаной смене слайдов opacity предыдущего слайда = 1 и его становится видно на долю секунды
const beforeTransitionStart = swiper => {
  swiper.slides.forEach((slide, index) => {
    if (index != swiper.activeIndex) {
      slide.style.opacity = 0;
    }
  });
};

watch(
  bannerList,
  newValue => {
    if (newValue && newValue.length) {
      const i = Math.floor(Math.random() * newValue.length);
      currentBanner.value = newValue[i];
      currentSelect.value = i;
    }
  },
  { immediate: true, once: true }
);

const thumbsSwiper = ref(null);

function initSwiperBanner(swiper) {
  thumbsSwiper.value = swiper;
}
</script>

<style lang="scss" scoped>
.banner_main-top {
  .swiper-slide {
    &:first-child {
      width: 100%;
    }
  }
}
</style>
